/* Testimonials Section */
.testimonials {
  padding: 2rem;
  background-color: #f7f7f7; /* Color de fondo */
  overflow-x: hidden;
}

.testimonials__title {
  margin-bottom: 1.5rem; /* Espaciado debajo del título */
  color: #1E3A8A; /* Color del título */
}

.testimonials__item {
  background-color: #ffffff; /* Color de fondo de los testimonios */
  padding: 1.5rem; /* Espaciado interno */
  border-radius: 0.5rem; /* Bordes redondeados */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra */
  margin: 1rem; /* Espaciado externo */
  display: flex; /* Flexbox para alinear el contenido */
  flex-direction: column; /* Contenido en columna */
}

.testimonials__quote {
  font-style: italic; /* Cursiva para la cita */
  color: #333333; /* Color del texto */
  margin-bottom: 1rem; /* Espaciado debajo de la cita */
  font-size: 1.125rem; /* Tamaño de fuente */
}

.testimonials__author {
  font-weight: bold; /* Negrita para el autor */
  color: #1E3A8A; /* Color del texto del autor */
}
