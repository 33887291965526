/* Base Container */
.container {
  width: 100%; /* Asegura que el contenedor ocupe el ancho completo */
  max-width: 100%; /* Opcional si quieres mantener el contenedor fluido */
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box; /* Incluye el padding en el cálculo del ancho total */
}

/* Media Queries */
@media (max-width: 1024px) {
  .container {
    padding: 0 15px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }
}
